import React from 'react';
import { Card, CardContent, Typography, TextField, Button, Box, InputAdornment } from '@mui/material';

const InfoCard = ({ title, fields, editable = false, buttonText, buttonAction, sx }) => {
    return (
        <Card sx={{
            width: '100%',
            marginBottom: 0,
            position: 'relative',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            ...sx,
        }}>
            <CardContent>
                <Box >
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: '1rem',
                    }}>
                        <Typography
                            variant="body1"
                            component="h2"
                            sx={{
                                fontSize: '1rem',
                                textAlign: 'left',
                                color: '#FFFFFF',
                                fontStyle: 'italic',
                                fontWeight: 'bold',
                                textShadow: '2px 2px 4px rgba(0,0,0,0.3)', // ����������Ӱ
                            }}>
                            {title}
                        </Typography>
                    </Box>
                    {buttonText && buttonAction && (
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            right: '1rem'
                        }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={buttonAction}
                                size="small"
                                sx={{
                                    color: '#FFFFFF', // �ı���ɫ
                                    borderColor: '#FFFFFF', // �߿���ɫ
                                    backgroundColor: 'transparent', // ����͸��
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        borderColor: '#FFFFFF', // ��ͣʱ�߿���ɫ
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)', // ��ͣʱ����ɫ����΢��ɫ͸��
                                    },
                                }}
                            >
                                {buttonText}
                            </Button>
                        </Box>
                    )}
                </Box>
                <Box sx={{ mt: 2 }}>
                    {fields.map((field, index) => (
                        <TextField
                            key={index}
                            margin="dense"
                            label={field.label}
                            type={field.type}
                            fullWidth
                            name={field.name}
                            value={field.value}
                            InputProps={{
                                readOnly: !editable,
                                startAdornment: ((field.name === 'phoneNumber' || field.name === 'upiPhoneNumber') && editable) ? (
                                    <InputAdornment position="start">
                                        <span style={{ color: '#FFFFFF', fontWeight: 'bold' }}>+91</span>
                                    </InputAdornment>
                                ) : null,
                                sx: {
                                    color: '#FFFFFF', // ��������������ɫΪ��ɫ
                                    fontSize: '0.875rem',
                                    height: '32px',
                                    '& .MuiInputBase-input': {
                                        color: '#FFFFFF', // ȷ������������ɫΪ��ɫ
                                        padding: '10px 12px',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#FFFFFF', // ���ñ߿���ɫΪ��ɫ
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#FFFFFF', // ������ͣʱ�߿���ɫΪ��ɫ
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#FFFFFF', // ���þ۽�ʱ�߿���ɫΪ��ɫ
                                    },
                                    '&.Mui-focused .MuiInputBase-input': {
                                        color: '#FFFFFF', // �۽�ʱ������������ɫ
                                    },
                                    '&.Mui-disabled .MuiInputBase-input': {
                                        color: '#FFFFFF', // ����ʱ������������ɫ
                                    },
                                    // �����Զ�������ʽ
                                    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
                                        WebkitBoxShadow: '0 0 0 1000px #000000 inset !important', // �Զ��屳��ɫ
                                        WebkitTextFillColor: '#FFFFFF !important', // �Զ���������ɫ
                                    },
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: '#FFFFFF', // ���ñ�ǩ������ɫΪ��ɫ
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    top: '50%', // ������ǩλ��
                                    transform: 'translate(14px, -50%)', // ��ֱ����
                                    '&.MuiInputLabel-shrink': {
                                        top: 0,
                                        transform: 'translate(14px, -50%) scale(0.75)', // ��������ʱ��λ��
                                    },
                                }
                            }}
                            onChange={editable ? (event) => field.onChange(event) : undefined}
                            sx={{
                                color: '#FFFFFF', // �������������ɫΪ��ɫ
                                marginBottom: 1,
                                '& .MuiInputBase-root': {
                                    fontSize: '0.875rem',
                                    height: '32px',
                                    color: '#FFFFFF', // ȷ�������ĸ��ڵ�������ɫΪ��ɫ
                                },
                                '& .MuiFormLabel-root': {
                                    color: '#FFFFFF', // ȷ����ǩ������ɫΪ��ɫ
                                },
                            }}
                        />
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
};

export default InfoCard;
