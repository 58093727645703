import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import ButtonNormal from './ButtonNormal';

const TaskCard = ({ description, buttonText, buttonAction, buttonEnable, sx }) => {

    let buttonBaseColor = buttonEnable ? '#F39C12' : '#6E7B8B'; // ��ť��ɫ
    let buttonGradientStart = buttonEnable  ? '#F1C40F' : '#7B8B9A'; // ��ť���俪ʼ��ɫ
    let buttonGradientEnd = buttonEnable ? '#F39C12' : '#6E7B8B'; // ��ť���������ɫ

    return (
        <Card sx={{
            backgroundColor: '#3A5F96', // ��ɫ����
            background: 'linear-gradient(to bottom, #4863A0, #3A5F96)', // ���ϵ��µĽ��䱳��

            height: '80px',
            width: '100%',
            maxWidth: '100%',
            borderRadius: '15px', // Բ��
            color: '#FFFFFF', // ������ɫ
            fontWeight: 'bold', // ���ּӴ�

            textTransform: 'none', // ��ֹ����ת��Ϊ��д
            display: 'flex', // ʹ��flex����
            alignItems: 'center', // ��ֱ����
            justifyContent: 'center', // ˮƽ����

            ...sx,
        }}>
            <CardContent sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '100%',
                width: '90%',
            }}>
                <Box
                    sx={{
                        width: '60%',
                    }}
                >

                    {description}
                </Box>
                <ButtonNormal
                    onClick={buttonAction}
                    buttonInfo={buttonText}
                    colors={{
                        baseColor: buttonBaseColor,
                        gradientStart: buttonGradientStart,
                        gradientEnd: buttonGradientEnd,
                    }}
                    sx={{
                        display: 'flex',
                        width: '30%',
                        height: '50%', // ���ð�ť�ĸ߶�
                        marginLeft: 'auto'
                    }} // �ð�ť���Ҷ���
                />
            </CardContent>
        </Card>
    );
};

export default TaskCard;
