let botUserName = process.env.REACT_APP_BOT_USER_NAME;
let appName = process.env.REACT_APP_BOT_APP_NAME;
let channelUserName = process.env.REACT_APP_TG_CHANNEL_NAME;

export const getBotUserName = () => {
    return botUserName;
};

export const setBotUserName = (name) => {
    botUserName = name;
};

export const getAppName = () => {
    return appName;
}

export const setAppName = (name) => {
    appName = name;
}

export const getChannelUserName = () => {
    return channelUserName;
};

export const setChannelUserName = (name) => {
    channelUserName = name;
}
