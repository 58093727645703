import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';


const CountdownTimer = () => {
    const [timeLeft, setTimeLeft] = useState('');

    const getIndianTime = () => {
        const now = new Date();
        const offsetMinutes = now.getTimezoneOffset();
        const utcTimestamp = now.getTime() + (offsetMinutes * 60 * 1000);
        const indiaOffset = 5.5 * 60 * 60 * 1000;
        return new Date(utcTimestamp + indiaOffset);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            // ��ȡӡ���û�ʱ��
            const indianTime = getIndianTime();
            // ��ȡ�������ʱ�䣨23:59:59��
            const endOfDay = new Date(indianTime);
            endOfDay.setHours(23, 59, 59, 999); // ����Ϊ�������ʱ��
            // ����ʣ��ʱ��
            const timeDiff = endOfDay - indianTime;
            // ����Сʱ�����Ӻ���
            const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            setTimeLeft(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <HourglassEmptyIcon fontSize="small" />
            <Typography>
                {timeLeft}
            </Typography>
        </Box>
    );
};

export default CountdownTimer;
