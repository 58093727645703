// OpenADLoad.js
let sdkLoaded = false;
let isLoading = false;
let loadPromise = null;  // ���ڸ��ټ���״̬�� promise

export const loadAdSDK = async (onLoadSuccess, onLoadFailed) => {
    if (sdkLoaded) return true; // ����Ѿ����ع���ֱ�ӷ��سɹ�
    
    if (isLoading) return loadPromise; // ������ڼ��أ�ֱ�ӷ������е� promise�������ظ�����

    // ��� SDK û�м��ع���������������߼�
    isLoading = true;
    loadPromise = new Promise(async (resolve, reject) => {
        try {
            /* --- Check Before Every Release --- */
            //A-- > ������� https://protocol.openad.network/sdkloader.js;
            //B-- > �ҵ� window.openADSdkLoader.version = ��Ӧ���Ĳ����е�version;
            //C-- > �ҵ� window.openADSdkLoader.sdkURL = ��Ӧ���Ĳ����е�url;
            //D-- > �ҵ� window.openADSdkLoader.sdkName = ��Ӧ���Ĳ����е�name;

            const result = await PromiseLoadScript({
                name: 'openADJsSDK',
                version: '3.0.4',
                url: 'https://bf2055756e.node.openad.network/js/sdk.v3.js',
                noCache: true,
            });
            if (result) {
                sdkLoaded = true; // ��� SDK �Ѿ����سɹ�
                if (onLoadSuccess) onLoadSuccess();
                resolve(true);
            } else {
                if (onLoadFailed) onLoadFailed();
                resolve(false);
            }
        } catch (error) {
            console.error('Failed to load OpenAD SDK:', error);
            if (onLoadFailed) onLoadFailed();
            resolve(false);
        } finally {
            isLoading = false; // ���ؽ��������۳ɹ���ʧ��
        }
    });

    return loadPromise; // ���ص�ǰ���ص� promise
};

const PromiseLoadScript = (obj) => {
    return new Promise((resolve, reject) => {
        if (window.J$) {
            resolve(true);
        } else {
            if (obj.noCache) {
                obj.url = `${obj.url}?t=${new Date().valueOf()}`;
            }
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = obj.url;
            script.name = obj.name;
            script.version = obj.version;

            script.onerror = () => reject(false);

            script.onload = () => resolve(true);

            script.onreadystatechange = function () {
                if (this.readyState === 'loaded' || this.readyState === 'complete') {
                    resolve(true);
                }
            };

            document.body.appendChild(script);
        }
    });
};
