import React from 'react';
import { Box } from '@mui/material';
import ButtonNormal from '../components/ButtonNormal';

const ButtonSingle = ({ onClick, clickInfo }) => {
    return (
        <Box sx={{
            display: 'flex',
            width: '60%',
            height: '20%', // ���ð�ť�ĸ߶�
            mt: 2,
        }}>
            <ButtonNormal
                onClick={onClick}
                buttonInfo={clickInfo}
                colors={{
                    baseColor: '#F39C12',
                    gradientStart: '#F1C40F',
                    gradientEnd: '#F39C12',
                }}
            />
        </Box>
    );
};

export default ButtonSingle;
