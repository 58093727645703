import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import '../App.css';
import { postData } from '../communications/Backend';
import ButtonPair from '../components/ButtonPair';
import ButtonSingle from '../components/ButtonSingle';
import LoadingSpinner from '../components/LoadingSpinner';
import PopupCard from '../components/PopupCard';
import PopupContent from '../components/PopupContent';
import PopupInfo from '../components/PopupInfo';
import PopupTitle from '../components/PopupTitle';
import { trackEvent, trackException } from '../tools/GaTools';
import WithdrawProcess from './WithdrawProcess';
import { WithdrawRecordPopup } from './WithdrawRecord';
import WithdrawWalletInfoEdit from './WithdrawWalletInfoEdit';
import { DisplayBanner } from '../tools/OpenAD';


function Withdraw({ open, onClose, getTicket, updateTicket, miniAppVisitUrl, upiPayEnable }) {

    const [loading, setLoading] = useState(true);
    const [timeoutError, setTimeoutError] = useState(false);
    const [dataFetchError, setDataFetchError] = useState(false);
    const [walletEditOpen, setWalletEditOpen] = useState(false);

    const [openWithdrawRecord, setOpenWithdrawRecord] = useState(false);

    const [ifscFormData, setIfscFormData] = useState({
        name: '',
        ifscNumber: '',
        email: '',
        phoneNumber: '',
        bankAccount: '',
    });

    const [upiFormData, setUpiFormData] = useState({
        upiName: '',
        upiId: '',
        upiEmail: '',
        upiPhoneNumber: '',
    });

    const [withdrawInfo, setWithdrawInfo] = useState({
        pending: 0,
        withdrawn: 0,
        inProgress: 0,
        withdrawCount: 0,
        ticketRequirement: 0,
        withdrawStatus: 'idle',  // 'idle', 'loading', 'success', 'validation_success', 'error_network', 'error_insufficient_funds', 'error_insufficient_invitation', 'error_empty_wallet_info'
    });


    const updateWithdrawInfo = (newValues) => {
        setWithdrawInfo((prevRecord) => ({
            ...prevRecord,
            ...newValues,
        }));
    };
    

    const fetchUserWithdrawInfo = async () => {
        try {
            await postData(
                'withdraw-info',
                null,
                handleWithdrawRecordFetched,
                handleWithdrawRecordFetchError
            );
        } catch (error) {
            handleWithdrawRecordFetchError(error);
        }
    };

    const fetchUserWalletInfo = async () => {
        try {
            await postData(
                'wallet-info',
                null,
                handleWalletInfoFetched,
                handleWalletInfoFetchError,
            );
        } catch (error) {
            handleWalletInfoFetchError(error);
        }
    };

    const postWithdrawCheck = async() => {
        try {
            await postData(
                'withdraw-check',
                { 'isUpi': upiPayEnable },
                handleWithdrawCheckDataReceived,
                handleWithdrawCheckDataReceiveError
            );
        } catch (error) {
            handleWithdrawCheckDataReceiveError(error);
        }
    };

    const postWithdrawConfirm = async () => {
        try {
            await postData(
                'withdraw-confirm',
                { 'isUpi': upiPayEnable },
                handleWithdrawConfirmDataReceived,
                handleWithdrawConfirmDataReceiveError
            );
        } catch (error) {
            handleWithdrawConfirmDataReceiveError(error);
        }
    };

    const handleWithdrawRecordFetchError = (error) => {
        console.error('User Withdraw Failed', error);
        setDataFetchError(true);
        trackException({
            description: `User Withdraw Failed: ${error}`,
        });
    }

    const handleWithdrawRecordFetched = (data) => {
        updateWithdrawInfo({
            pending: data.pending,
            withdrawn: data.withdrawn,
            inProgress: data.inProgress,
        });
        trackEvent({
            category: 'User',
            action: 'Fetched',
            label: 'WithdrawInfo',
        });
    }

    const handleWalletInfoFetched = (data) => {
        const { upiName, upiId, upiEmail, upiPhoneNumber } = data;
        setUpiFormData({ upiName, upiId, upiEmail, upiPhoneNumber });
        const { name, ifscNumber, email, phoneNumber, bankAccount } = data;
        setIfscFormData({ name, ifscNumber, email, phoneNumber, bankAccount });
    }

    const handleWalletInfoFetchError = (error) => {
        console.error('User Wallet Failed', error);
        setDataFetchError(true);
        trackException({
            description: `User Wallet Failed: ${error}`,
        });
    }

    const handleWithdrawCheckDataReceiveError = (error) => {
        console.error('User Withdraw Failed', error);
        updateWithdrawInfo({
            withdrawStatus: 'error_network',
        });
        trackException({
            description: `User Withdraw Failed: ${error}`,
        });
    }

    const handleWithdrawConfirmDataReceiveError = (error) => {
        console.error('User Withdraw Confirm Failed', error);
        updateWithdrawInfo({
            withdrawStatus: 'error_network',
        });
        trackException({
            description: `User Withdraw Confirm Failed: ${error}`,
        });
    }


    const handleWithdrawCheckDataReceived = (data) => {
        switch (data.state) {
            case 'validation_success':
                updateWithdrawInfo({
                    pending: data.pending,
                    ticketRequirement: data.ticketRequirement,
                    withdrawStatus: 'validation_success',
                });
                updateTicket(data.ticketNumber);
                break;
            case 'error_insufficient_funds':
                updateWithdrawInfo({
                    pending: data.pending,
                    withdrawStatus: 'error_insufficient_funds',
                });
                break;
            case 'error_insufficient_invitation':
                updateWithdrawInfo({
                    ticketRequirement: data.ticketRequirement,
                    withdrawStatus: 'error_insufficient_invitation',
                });
                updateTicket(data.ticketNumber);
                break;
            case 'error_empty_wallet_info':
                updateWithdrawInfo({
                    withdrawStatus: 'error_empty_wallet_info',
                });
                break;
            default:
                updateWithdrawInfo({
                    withdrawStatus: 'error_network',
                });
                break;
        }
        trackEvent({
            category: 'User',
            action: 'Fetched',
            label: `WithdrawCheckData-${data.state}`,
        });
    }

    const handleWithdrawConfirmDataReceived = (data) => {
        switch (data.state) {
            case 'success':
                updateWithdrawInfo({
                    pending: data.pending,
                    withdrawn: data.withdrawn,
                    inProgress: data.inProgress,
                    withdrawCount: data.withdrawCount,
                    withdrawStatus: 'success',
                });
                updateTicket(data.ticketNumber);
                break;
            case 'error_insufficient_funds':
                updateWithdrawInfo({
                    pending: data.pending,
                    withdrawStatus: 'error_insufficient_funds',
                });
                break;
            case 'error_insufficient_invitation':
                updateWithdrawInfo({
                    ticketRequirement: data.ticketRequirement,
                    withdrawStatus: 'error_insufficient_invitation',
                });
                updateTicket(data.ticketNumber);
                break;
            case 'error_empty_wallet_info':
                updateWithdrawInfo({
                    withdrawStatus: 'error_empty_wallet_info',
                });
                break;
            default:
                updateWithdrawInfo({
                    withdrawStatus: 'error_network',
                });
                break;
        }
        trackEvent({
            category: 'User',
            action: 'Fetched',
            label: `WithdrawConfirmData-${data.state}`,
        });
    }

    const handleWithdrawConfirmClick = async () => {
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: "WithdrawConfirm",
        });
        updateWithdrawInfo({
            withdrawStatus: 'loading',
        });
        try {
            await postWithdrawConfirm();
        } catch (error) {
            handleWithdrawConfirmDataReceiveError(error);
        }
    }

    const handleWithdrawCheckClick = async () => {
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: "WithdrawCheck",
        });
        updateWithdrawInfo({
            withdrawStatus: 'loading',
        });
        try {
            await postWithdrawCheck();
        } catch (error) {
            handleWithdrawCheckDataReceiveError(error);
        }
    };

    useEffect(() => {
        if (open) {
            setLoading(true);
            setTimeoutError(false);
            setDataFetchError(false);


            const timeoutId = setTimeout(() => {
                setLoading(false);
                setTimeoutError(true);
                trackException({
                    description: `User Withdraw info Time out`,
                });
            }, 10000);

            const fetchDataTmp = async () => {
                try {
                    await fetchUserWithdrawInfo();
                    await fetchUserWalletInfo();
                    clearTimeout(timeoutId);
                    setLoading(false);
                } catch (error) {
                    clearTimeout(timeoutId);
                    setLoading(false);
                    setDataFetchError(true);
                }
            };

            fetchDataTmp();

            return () => clearTimeout(timeoutId);
        }
    }, [open]);

    return (
        <>
            {loading && (<LoadingSpinner />)}

            <PopupCard
                open={timeoutError}
                onClose={onClose}
            >
                <PopupTitle title="Error" />
                <PopupContent>
                    <PopupInfo info={
                        `Network connection error
                         Try again later!` } />
                </PopupContent>
                <ButtonSingle onClick={onClose} clickInfo="Close" />
            </PopupCard>

            <PopupCard
                open={dataFetchError}
                onClose={onClose}
            >
                <PopupTitle title="Error" />
                <PopupContent>
                    <PopupInfo info={
                        `Network connection error
                         Try again later!` } />
                </PopupContent>
                <ButtonSingle onClick={onClose} clickInfo="Close" />
            </PopupCard>

            <PopupCard
                open={open}
                onClose={onClose}
                width="100%"
            >
                <PopupContent>
                    <WithdrawProcess
                        withdrawInfo={withdrawInfo}
                        getTicket={getTicket}
                        closeWithdrawProgress={() => updateWithdrawInfo({ withdrawStatus: 'idle' })}
                        openWalletInfoEdit={() => setWalletEditOpen(true)}
                        openWithdrawRecord={() => setOpenWithdrawRecord(true)}
                        confirmWithdraw={handleWithdrawConfirmClick}
                        miniAppVisitUrl={miniAppVisitUrl}
                    />
                    <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 0 }}>
                        <DisplayBanner
                            bannerIndex={1}
                            slot="withdraw"
                        />
                    </Box>
                    <WithdrawWalletInfoEdit
                        upiPayEnable={upiPayEnable}
                        ifscFormData={ifscFormData}
                        setIfscFormData={setIfscFormData}
                        upiFormData={upiFormData}
                        setUpiFormData={setUpiFormData}
                        editOpen={walletEditOpen}
                        setEditOpen={setWalletEditOpen}
                    />
                </PopupContent>
                <ButtonPair
                    confirmInfo="Cash Out"
                    closeInfo="Close"
                    onConfirm={handleWithdrawCheckClick}
                    onClose={onClose}
                />
            </PopupCard>

            {openWithdrawRecord &&
                <WithdrawRecordPopup
                    open={openWithdrawRecord}
                    onClose={() => setOpenWithdrawRecord(false)}
                    setWithdrawInfo={setWithdrawInfo}
                />
            }
        </>
    );
}

export default Withdraw;
