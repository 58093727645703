import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const TransparentButton = styled(Button)({
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const TextBox = styled(Box)({
    backgroundColor: 'transparent', // ʹ����͸��
    height: '25px', // �����ı���߶�
    width: '64px', // 
    paddingLeft: '15px', // ��߾�
    paddingRight: '15px', // �ұ߾�
    borderRadius: '8px',
    marginTop: '0px',
    textAlign: 'center', // ���־���
    textTransform: 'none',
    textShadow: '1px 1px 2px #000000',
    display: 'flex', // ʹ�� flex ����
    alignItems: 'center', // ��ֱ����
    justifyContent: 'center', // ˮƽ����
    background: 'linear-gradient(to bottom, #6BA3FF, #4A7BCE)', // ���䱳��ɫ
    boxShadow: 'inset 0 1px 2px rgba(255, 255, 255, 0.8), inset 0 -1px 2px rgba(0, 0, 0, 0.2)', // �ڲ���ӰЧ��
});

const ButtonWithLogo = ({ imageSrc, text, onClick }) => {
    return (
        <TransparentButton onClick={onClick}>
            <img src={imageSrc} alt="icon" style={{ width: '44px', height: '44px' }} />
            <TextBox>
                <Typography variant="h7" color="white" sx={{ display: 'flex', whiteSpace: 'nowrap', }} >
                    {text}
                </Typography>
            </TextBox>
        </TransparentButton>
    );
};

export default ButtonWithLogo;