import React from 'react';
import { Button, Box } from '@mui/material';
import { styled } from '@mui/system';

const BlueButton = styled(Button)({
    backgroundColor: '#4A7BCE', // ������ɫ
    background: 'linear-gradient(to bottom, #6BA3FF, #4A7BCE)', // ���䱳��ɫ
    boxShadow: 'inset 0 1px 2px rgba(255, 255, 255, 0.8), inset 0 -1px 2px rgba(0, 0, 0, 0.2)', // �ڲ���ӰЧ��
    height: '50px',
    width: '300px',
    maxWidth: '80%',
    borderRadius: '20px', // Բ��
    color: '#FFFFFF', // ������ɫ
    fontWeight: 'bold', // ���ּӴ�

    textTransform: 'none', // ��ֹ����ת��Ϊ��д
    display: 'flex', // ʹ��flex����
    alignItems: 'center', // ��ֱ����
    justifyContent: 'center', // ˮƽ����
});

const YellowButton = styled(Button)({
    backgroundColor: '#F39C12', // ������ɫ
    background: 'linear-gradient(to bottom, #F1C40F, #F39C12)', // ���䱳��ɫ
    boxShadow: 'inset 0 1px 2px rgba(255, 255, 255, 0.8), inset 0 -1px 2px rgba(0, 0, 0, 0.2)', // �ⲿ��ӰЧ��
    height: '50px',
    width: '300px',
    maxWidth: '80%',
    borderRadius: '20px', // Բ��
    color: '#FFFFFF', // ������ɫ
    fontWeight: 'bold', // ���ּӴ�

    textTransform: 'none', // ��ֹ����ת��Ϊ��д
    display: 'flex', // ʹ��flex����
    alignItems: 'center', // ��ֱ����
    justifyContent: 'center', // ˮƽ����
});

const PurpleButton = styled(Button)({
    backgroundColor: '#8E44AD', // ������ɫ
    background: 'linear-gradient(to bottom, #9B59B6, #8E44AD)', // ���䱳��ɫ
    boxShadow: 'inset 0 1px 2px rgba(255, 255, 255, 0.8), inset 0 -1px 2px rgba(0, 0, 0, 0.2)', // �ⲿ��ӰЧ��
    height: '50px',
    width: '300px',
    maxWidth: '80%',
    borderRadius: '20px', // Բ��
    color: '#FFFFFF', // ������ɫ
    fontWeight: 'bold', // ���ּӴ�

    textTransform: 'none', // ��ֹ����ת��Ϊ��д
    display: 'flex', // ʹ��flex����
    alignItems: 'center', // ��ֱ����
    justifyContent: 'center', // ˮƽ����
});


const ButtonWithIcon = ({ color, children, onClick }) => {
    let ButtonComponent = BlueButton;
    // ���� color ����ѡ��ͬ�İ�ť��ʽ
    switch (color) {
        case 'blue':
            ButtonComponent = BlueButton;
            break;
        case 'yellow':
            ButtonComponent = YellowButton;
            break;
        case 'purple':
            ButtonComponent = PurpleButton;
            break;
        default:
            ButtonComponent = BlueButton; // Ĭ��Ϊ��ɫ��ť
            break;
    }

    return (
        <ButtonComponent onClick={onClick}>
            {children && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pointerEvents: 'none', // ȷ��children������Ž������ĵ���¼�
                    }}
                >
                    {children}
                </Box>
            )}
        </ButtonComponent>
    );
};


export default ButtonWithIcon;
