import React from 'react';
import { Typography } from '@mui/material';
import PopupCard from '../components/PopupCard'; // ���� PopupCard ���
import PopupTitle from '../components/PopupTitle';
import PopupContent from '../components/PopupContent';
import ButtonSingle from '../components/ButtonSingle';
export function Rules({ open, onClose }) {
    const welcome = `Welcome to Winzy Slot Machine!`;

    const rulesTitle = `Here are the rules you need to know:`;

    const rules = `1. Everyone starts with 1 free gems.

        2. When you run out of gems, don't worry! You can earn extra gems by inviting your friends. Each time a friend joins the channel through your invitation, you will receive additional gems.

        3. Each time the Slot Machine starts spinning, you will receive a different amount of rewards, depending on your luck. Once you reach a certain amount, you can cash out. Manual review will take 1-7 working days.

        4. Winzy holds all rights to rule interpretation.`;

    const welcomTitle = `Let's play the Slot Machine to Earn!`;

    const RulesInfo = () => {
        return (
            <>
                <Typography
                    variant="body2"
                    width="100%"
                    sx={{
                        fontWeight: 'bold',
                        color: '#FFFFFF',
                        textShadow: '2px 2px 4px rgba(0,0,0,0.3)', // ����������Ӱ
                        textAlign: 'left', // �ı�����
                        whiteSpace: 'pre-line', // �������з�
                        mt: 0,
                    }}>
                    {welcome}
                </Typography>
                <Typography
                    variant="body1"
                    width="100%"
                    sx={{
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        color: '#FFFFFF',
                        textShadow: '2px 2px 4px rgba(0,0,0,0.3)', // ����������Ӱ
                        textAlign: 'left', // �ı�����
                        whiteSpace: 'pre-line', // �������з�
                        mt: 1,
                    }}>
                    {rulesTitle}
                </Typography>
                <Typography
                    variant="body2"
                    width="100%"
                    sx={{
                        color: '#FFFFFF',
                        textShadow: '2px 2px 4px rgba(0,0,0,0.3)', // ����������Ӱ
                        textAlign: 'left', // �ı�����
                        whiteSpace: 'pre-line', // �������з�
                        mt: 0,
                    }}>
                    {rules}
                </Typography>
                <Typography
                    variant="body1"
                    width="100%"
                    sx={{
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        color: '#FFFFFF',
                        textShadow: '2px 2px 4px rgba(0,0,0,0.3)', // ����������Ӱ
                        textAlign: 'left', // �ı�����
                        whiteSpace: 'pre-line', // �������з�
                        mt: 2,
                    }}>
                    {welcomTitle}
                </Typography>
            </>
        );
    }

    return (
        <PopupCard
            open={open}
            onClose={onClose}
            width="100%"
        >
            <PopupTitle title="Game Rules" />
            <PopupContent>
                <RulesInfo />
            </PopupContent>
            <ButtonSingle onClick={onClose} clickInfo="Close" />

        </PopupCard>
    );
}
