import React from 'react';
import Box from '@mui/material/Box';

const LinearProgressBar = ({ current, total, children }) => {
    const progress = (current / total) * 100;

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', padding: 2 }}>
            <Box
                sx={{
                    width: '350px',
                    maxWidth: '100%',
                    height: 30,
                    background: 'linear-gradient(to bottom, #F1C40F, #F39C12)', // ģ���ɫ������Ľ���
                    borderRadius: 4,
                    position: 'relative',
                    overflow: 'hidden', // ȷ����������������߿�
                    padding: 1, // �ڱ߾࣬�����������ռ�
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        background: 'linear-gradient(to bottom, #8B4513, #A0522D)', // ģ���ɫ������Ľ���
                        borderRadius: 2.5,
                        boxShadow: 'inset 0 2px 5px rgba(0, 0, 0, 0.5)', // �ڲ���ӰЧ��
                        position: 'relative',
                        overflow: 'hidden', // ȷ����������������߿�
                        padding: 0, // �ڱ߾࣬�����������ռ�
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            width: `${progress}%`,
                            borderRadius: 'inherit',
                            boxShadow: 'inset 0 0px 5px rgba(0, 0, 0, 0.5)', // �ڲ���ӰЧ��
                            background: 'linear-gradient(to bottom, #E8FFB3, #A5E24D, #80CE33)',
                            transition: 'width 0.3s ease-in-out', // ����Ч��
                        }}
                    />
                </Box>
                {children && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            pointerEvents: 'none', // ȷ��children������Ž������ĵ���¼�
                        }}
                    >
                        {children}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default LinearProgressBar;
