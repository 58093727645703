import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { postData } from '../communications/Backend';
import { trackException, trackEvent } from '../tools/GaTools';
import LoadingSpinner from '../components/LoadingSpinner';
import PopupCard from '../components/PopupCard'; // ���� PopupCard ���
import PopupTitle from '../components/PopupTitle';
import PopupContent from '../components/PopupContent';
import PopupInfo from '../components/PopupInfo';
import ButtonPair from '../components/ButtonPair';
import ButtonSingle from '../components/ButtonSingle';

export function Invitation({ open, onClose }) {

    const [invitationCount, setInvitationCount] = useState(0);
    const [invitationRecord, setInvitationRecord] = useState([]);
    const [loading, setLoading] = useState(true);
    const [timeoutError, setTimeoutError] = useState(false);
    const [dataFetchError, setDataFetchError] = useState(false);

    const handleInvitationRecordFetchSuccess = (data) => {
        
        setInvitationCount(data.invitationCount)
        const formattedRecords = data.invitations.map(record => ({
            ...record,
            time: new Date(record.time).toLocaleString(),
        }));
        setInvitationRecord(formattedRecords);
        trackEvent({
            category: 'User',
            action: 'Fetched',
            label: 'InvitationRecord',
        });
    };

    const handleInvitationRecordFetchError = (error) => {
        console.error('User Invitation Fetched Failed', error);
        setDataFetchError(true);
        trackException({
            description: `User Invitation Fetched Failed: ${error}`,
        });
    };

    const fetchUserInvitationRecord = async () => {
        try {
            await postData(
                'invitation-record',
                null,
                handleInvitationRecordFetchSuccess,
                handleInvitationRecordFetchError
            );
        } catch (error) {
            handleInvitationRecordFetchError(error)
        }
    };

    const fetchAndRefreshData = async () => {
        setLoading(true);
        setTimeoutError(false);
        setDataFetchError(false);

        const timeoutId = setTimeout(() => {
            setLoading(false);
            setTimeoutError(true);
            console.log('Invitation Time Out');
            trackException({
                description: `User Invitation Fetched Time out`,
            });
        }, 10000); // 10�볬ʱ

        try {
            await fetchUserInvitationRecord();
            clearTimeout(timeoutId);
            setLoading(false);
        } catch (error) {
            clearTimeout(timeoutId);
            setLoading(false);
            setDataFetchError(true);
            trackException({
                description: `User Invitation Fetched Failed: ${error}`,
            });
        }

        return timeoutId;
    };

    const handleInvitationRefresh = () => {
        fetchAndRefreshData();
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: "InvitationRefresh",
        });
    };

    useEffect(() => {
        if (open) {
            const timeoutId = fetchAndRefreshData();
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [open]);

    const InvitationRecordTotal = ({ count }) => {
        return (
            <Typography
                variant="body1"
                width="100%"
                sx={{
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                    color: '#FFFFFF',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)', // ����������Ӱ
                    textAlign: 'left', // �ı�����
                    whiteSpace: 'pre-line', // �������з�
                    mt: 0,
                }}>
                You have invited <span style={{ color: '#FFEBA2', fontSize: '1.5rem' }}>{count}</span> friends:
            </Typography>
        );
    }

    const InvitationRecordDescription = () => {
        return (
            <Typography
                variant="body2"
                width="100%"
                sx={{
                    color: '#FFFFFF',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)', // ����������Ӱ
                    textAlign: 'left', // �ı�����
                    whiteSpace: 'pre-line', // �������з�
                    mt: 0,
                }}>
                {`1. If the person you invite doesn't join our channel, you can't get tickets!
                  2. Only show recent 50 invitations:`}
            </Typography>
        );
    }

    const InvitationRecordDetail = ({ record }) => {
        if (record.length > 0) {
            return (
                <Box
                    width="100%"
                    sx={{
                        color: '#FFFFFF',
                        textAlign: 'left', // �ı�����
                        textShadow: '2px 2px 4px rgba(0,0,0,0.3)', // ����������Ӱ
                        mt: 1,
                    }}
                >
                    {record.map((record, index) => (
                        <Box key={index}>
                            <Typography>{record.time} -- {record.userName}</Typography>
                        </Box>
                    ))}
                </Box>
            );
        } else {
            return null;
        }
    }

    return (
        <>
            <PopupCard
                open={open}
                onClose={onClose}
                width="100%"
            >
                <PopupTitle title="Invitation History" />
                <PopupContent>
                    <InvitationRecordTotal count={invitationCount} />
                    <InvitationRecordDescription/>
                    <InvitationRecordDetail record={invitationRecord} />
                </PopupContent>
                <ButtonPair
                    confirmInfo="Refresh"
                    closeInfo="Close"
                    onConfirm={handleInvitationRefresh}
                    onClose={onClose}
                />
            </PopupCard>

            {loading && <LoadingSpinner /> }

            <PopupCard
                open={timeoutError}
                onClose={onClose}
            >
                <PopupTitle title="Error" />
                <PopupContent>
                    <PopupInfo info={
                        `Network connection error
                         Try again later!` } />
                </PopupContent>
                <ButtonSingle onClick={onClose} clickInfo="Close" />
            </PopupCard>

            <PopupCard
                open={dataFetchError}
                onClose={onClose}
            >
                <PopupTitle title="Error" />
                <PopupContent>
                    <PopupInfo info={
                        `Network connection error
                         Try again later!` } />
                </PopupContent>
                <ButtonSingle onClick={onClose} clickInfo="Close" />
            </PopupCard>
        </>
    );
}
