import React from 'react';
import { Box, Typography } from '@mui/material';

const PopupTitle = ({ title }) => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '40px',
                textAlign: 'center',
                color: '#FFFFFF',
                fontStyle: 'italic',
                fontWeight: 'bold',
                textShadow: '2px 2px 4px rgba(0,0,0,0.8)', // ����������Ӱ
                mt: -1,
                mb: 1,
                whiteSpace: 'nowrap', // ȷ���ı�������

            }}
        >
            <Typography
                variant="h4"
                component="h2"
            >
                {title}
            </Typography>
        </Box>
    );
};

export default PopupTitle;