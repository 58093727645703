import React from 'react';
import { Box } from '@mui/material';
import ButtonNormal from '../components/ButtonNormal';

const ButtonPair = ({ onClose, closeInfo, onConfirm, confirmInfo, buttonAllHighlight }) => {
    const defaultColors = {
        gray: {
            baseColor: '#6E7B8B',
            gradientStart: '#7B8B9A',
            gradientEnd: '#6E7B8B',
        },
        yellow: {
            baseColor: '#F39C12',
            gradientStart: '#F1C40F',
            gradientEnd: '#F39C12',
        },
    };
    const closeButtonColors = buttonAllHighlight ? defaultColors.yellow : defaultColors.gray;
    const confirmButtonColors = defaultColors.yellow

    return (
        <Box sx={{
            display: 'flex',
            width: '100%',
            height: '20%', // ���ð�ť�ĸ߶�
            mt: 2,
        }}>
            <ButtonNormal
                onClick={onClose}
                buttonInfo={closeInfo}
                colors={closeButtonColors}
                sx={{
                    ml: 0,
                    mr: 1,
                }}
            />

            <ButtonNormal
                onClick={onConfirm}
                buttonInfo={confirmInfo}
                colors={confirmButtonColors}
                sx={{
                    ml: 1,
                    mr: 0,
                }}
            />
        </Box>
    );
};

export default ButtonPair;
