import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { postData } from '../communications/Backend';
import PopupCard from '../components/PopupCard';
import PopupTitle from '../components/PopupTitle';
import PopupContent from '../components/PopupContent';
import PopupInfo from '../components/PopupInfo';
import ButtonPair from '../components/ButtonPair';
import ButtonSingle from '../components/ButtonSingle';
import InfoCard from '../components/InfoCard';
import LoadingSpinner from '../components/LoadingSpinner';
import { trackException, trackEvent } from '../tools/GaTools';

const WithdrawWalletInfoEdit = ({ upiPayEnable, ifscFormData, setIfscFormData, upiFormData, setUpiFormData, editOpen, setEditOpen }) => {
    const [commitWaitingStatus, setCommitWaitingStatus] = useState('idle'); // 'idle', 'loading', 'success', 'errorNetwork', 'errorFormat'

    const ifscValidationSchema = Yup.object({
        name: Yup.string().matches(/^[a-zA-Z\s'-.,()]*$/, 'Name must only contain letters, spaces, \'-\', \',\', \'.\', and \'()\'').required('Name is required'),
        ifscNumber: Yup.string().matches(/^[A-Za-z]{4}0[A-Za-z0-9]{6}$/, 'Invalid IFSC code format').required('IFSC Number is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        phoneNumber: Yup.string().matches(/^\d{10}$/, 'Phone Number must be 10 digits').required('Phone Number is required'),
        bankAccount: Yup.string().matches(/^\d+$/, 'Bank Account must be a numeric value').required('Bank Account is required'),
    });

    const upiValidationSchema = Yup.object({
        upiName: Yup.string().matches(/^[a-zA-Z\s'-.,()]*$/, 'Name must only contain letters, spaces, \'-\', \',\', \'.\', and \'()\'').required('Name is required'),
        upiId: Yup.string().matches(/^[a-zA-Z0-9._-]+@[a-zA-Z.-]+$/, 'Invalid UPI ID format').required('UPI ID is required'),
        upiEmail: Yup.string().email('Invalid email address').required('Email is required'),
        upiPhoneNumber: Yup.string().matches(/^\d{10}$/, 'Phone Number must be 10 digits').required('Phone Number is required'),
    });

    const ifscFormik = useFormik({
        initialValues: { ...ifscFormData },
        validationSchema: ifscValidationSchema,
        onSubmit: async (values) => {
            setCommitWaitingStatus('loading');
            try {
                await postData(
                    'wallet-info-submit',
                    values,
                    (data) => {
                        if (data.state === "success") {
                            setCommitWaitingStatus('success');
                            setIfscFormData(values);
                        } else {
                            setCommitWaitingStatus('errorFormat');
                            console.error('Form incorrect');
                            trackException({
                                description: `User Wallet Info Submit Failed: Backend Refuse`,
                            });
                        }
                    },
                    (error) => {
                        setCommitWaitingStatus('errorNetwork');
                        console.error('Failed to submit form:', error);
                        trackException({
                            description: `User Wallet Info Submit Failed: ${error}`,
                        });
                    }
                );
            } catch (error) {
                setCommitWaitingStatus('errorNetwork');
                console.error('Failed to submit form catched:', error);
                trackException({
                    description: `User Wallet Info Submit Failed: ${error}`,
                });
            }
        },
    });

    const upiFormik = useFormik({
        initialValues: { ...upiFormData },
        validationSchema: upiValidationSchema,
        onSubmit: async (values) => {
            setCommitWaitingStatus('loading');
            try {
                await postData(
                    'wallet-info-submit',
                    values,
                    (data) => {
                        if (data.state === "success") {
                            setCommitWaitingStatus('success');
                            setUpiFormData(values);
                        } else {
                            setCommitWaitingStatus('errorFormat');
                            console.error('Form incorrect');
                            trackException({
                                description: `User Wallet Info Submit Failed: Backend Refuse`,
                            });
                        }
                    },
                    (error) => {
                        setCommitWaitingStatus('errorNetwork');
                        console.error('Failed to submit form:', error);
                        trackException({
                            description: `User Wallet Info Submit Failed: ${error}`,
                        });
                    }
                );
            } catch (error) {
                setCommitWaitingStatus('errorNetwork');
                console.error('Failed to submit form catched:', error);
                trackException({
                    description: `User Wallet Info Submit Failed: ${error}`,
                });
            }
        },
    });

    useEffect(() => {
        if (upiPayEnable) {
            upiFormik.setValues({ ...upiFormData });
        } else {
            ifscFormik.setValues({ ...ifscFormData });
        }
    }, [upiPayEnable, ifscFormData, upiFormData]);

    const handleWalletEditClose = () => {
        setCommitWaitingStatus('idle');
        setEditOpen(false);
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: "WalletEditClose",
        });
    };

    const handleWalletEditContinue = () => {
        setCommitWaitingStatus('idle');
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: "WalletEditContinue",
        });
    }

    const handleWalletEditSubmit = async () => {
        if (upiPayEnable) {
            trackEvent({
                category: 'User',
                action: 'ButtonClick',
                label: `WalletEditSubmit-${upiFormik.isValid}`,
            });

            if (upiFormik.isValid) {
                upiFormik.handleSubmit();
            } else {
                setCommitWaitingStatus('errorFormat');
            }
        } else {
            trackEvent({
                category: 'User',
                action: 'ButtonClick',
                label: `WalletEditSubmit-${ifscFormik.isValid}`,
            });

            if (ifscFormik.isValid) {
                ifscFormik.handleSubmit();
            } else {
                setCommitWaitingStatus('errorFormat');
            }
        }
    };

    const renderWalletInfo = () => {
        const fields = upiPayEnable ?
            [
                { label: 'Name', type: 'text', name: 'upiName', value: upiFormData.upiName },
                { label: 'UPI ID', type: 'text', name: 'upiId', value: upiFormData.upiId },
                { label: 'Email', type: 'email', name: 'upiEmail', value: upiFormData.upiEmail },
                { label: 'Mobile Number', type: 'tel', name: 'upiPhoneNumber', value: upiFormData.upiPhoneNumber },
            ] :
            [
                { label: 'Name', type: 'text', name: 'name', value: ifscFormData.name },
                { label: 'Account Number', type: 'text', name: 'bankAccount', value: ifscFormData.bankAccount },
                { label: 'IFSC', type: 'text', name: 'ifscNumber', value: ifscFormData.ifscNumber },
                { label: 'Email', type: 'email', name: 'email', value: ifscFormData.email },
                { label: 'Mobile Number', type: 'tel', name: 'phoneNumber', value: ifscFormData.phoneNumber },
            ];
        return (
            <InfoCard
                title="Account Info"
                fields={fields}
                buttonText="Edit"
                buttonAction={() => setEditOpen(true)}
                sx={{
                    minHeight: '17.5rem',
                }}
            />
        );
    };

    const renderWalletEdit = () => {
        const fields = upiPayEnable ?
            [
                { label: 'Name on Bank Account', type: 'text', name: 'upiName', value: upiFormik.values.upiName, onChange: upiFormik.handleChange, error: upiFormik.errors.upiName },
                { label: 'UPI ID', type: 'text', name: 'upiId', value: upiFormik.values.upiId, onChange: upiFormik.handleChange, error: upiFormik.errors.upiId },
                { label: 'Email', type: 'email', name: 'upiEmail', value: upiFormik.values.upiEmail, onChange: upiFormik.handleChange, error: upiFormik.errors.upiEmail },
                { label: 'Mobile Number', type: 'tel', name: 'upiPhoneNumber', value: upiFormik.values.upiPhoneNumber, onChange: upiFormik.handleChange, error: upiFormik.errors.upiPhoneNumber },            ] :
            [
                { label: 'Name on Bank Account', type: 'text', name: 'name', value: ifscFormik.values.name, onChange: ifscFormik.handleChange, error: ifscFormik.errors.name },
                { label: 'Account Number - IMPS(No UPI Support)', type: 'text', name: 'bankAccount', value: ifscFormik.values.bankAccount, onChange: ifscFormik.handleChange, error: ifscFormik.errors.bankAccount },
                { label: 'IFSC', type: 'text', name: 'ifscNumber', value: ifscFormik.values.ifscNumber, onChange: ifscFormik.handleChange, error: ifscFormik.errors.ifscNumber },
                { label: 'Email', type: 'email', name: 'email', value: ifscFormik.values.email, onChange: ifscFormik.handleChange, error: ifscFormik.errors.email },
                { label: 'Mobile Number', type: 'tel', name: 'phoneNumber', value: ifscFormik.values.phoneNumber, onChange: ifscFormik.handleChange, error: ifscFormik.errors.phoneNumber },
            ];
        return (
            <PopupCard
                open={editOpen}
                onClose={handleWalletEditClose}
            >
                <PopupContent>
                    <form onSubmit={handleWalletEditSubmit}>
                        <InfoCard
                            title="Edit Account Info"
                            editable={true}
                            fields={fields}
                            sx={{
                                minHeight: '17.5rem',
                            }}
                        />
                        <ButtonPair
                            confirmInfo="Save"
                            closeInfo="Close"
                            onConfirm={handleWalletEditSubmit}
                            onClose={handleWalletEditClose}
                        />
                    </form>
                </PopupContent>
                {renderCommitWaitingPopup()}
            </PopupCard>
        );
    };

    const renderCommitWaitingPopup = () => {
        switch (commitWaitingStatus) {
            case 'idle':
                return null;
            case 'loading':
                return <LoadingSpinner />;
            case 'success':
                return (
                    <PopupCard
                        open={true}
                        onClose={handleWalletEditClose}
                        width="70%"
                    >
                        <PopupTitle title="Congratulations" />
                        <PopupContent>
                            <PopupInfo info="Account Info submitted successfully!" />
                        </PopupContent>
                        <ButtonSingle onClick={handleWalletEditClose} clickInfo="Close" />
                    </PopupCard>
                );
            case 'errorNetwork':
                return (
                    <PopupCard
                        open={true}
                        onClose={handleWalletEditClose}
                        width="70%"
                    >
                        <PopupTitle title="Error" />
                        <PopupContent>
                            <PopupInfo info={
                                `Network connection error
                                 Try again later!` } />
                        </PopupContent>
                        <ButtonSingle onClick={handleWalletEditClose} clickInfo="Close" />
                    </PopupCard>
                );
            case 'errorFormat':
                return (
                    <PopupCard
                        open={true}
                        onClose={handleWalletEditContinue}
                        width="70%"
                    >
                        <PopupTitle title="Error" />
                        <PopupContent>
                            <PopupInfo info={
                                `Account Info form contains errors
                                 Please correct them and resubmit!`} />
                        </PopupContent>
                        <ButtonSingle onClick={handleWalletEditContinue} clickInfo="Close" />
                    </PopupCard>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {renderWalletInfo()}
            {renderWalletEdit()}
        </>
    );
};

export default WithdrawWalletInfoEdit;
