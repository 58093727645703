import React from 'react';
import { Box, Typography } from '@mui/material';

const PopupInfo = ({ info }) => {
    return (
        <Box
            sx={{
                display: 'flex', // ʹ��flex����
                flexDirection: 'column', // ȷ����Ԫ�ش�ֱ����
                justifyContent: 'center', // ��ֱ����
                alignItems: 'center', // ˮƽ����
                width: '100%',
                color: '#FFFFFF',
                fontWeight: 'bold',
                textShadow: '1px 1px 2px rgba(0,0,0,0.3)', // ����������Ӱ
                maxWidth: '80%',
                maxHeight: '80%',
                whiteSpace: 'pre-line', // �������з�
                overflow: 'auto',
                mt: 0,
                mb: 2,
                textAlign: 'center', // ȷ���ı�ˮƽ����
            }}
        >
            <Typography
                variant="body1"
                component="div"
                sx={{
                    width: '100%', // ȷ�� Typography ռ�� Box ��ȫ������
                    textAlign: 'center' // ȷ���ı��� Typography �ھ���
                }}
            >
                {info}
            </Typography>
        </Box>
    );
};

export default PopupInfo;