import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';

const getRandomNumber = () => Math.floor(Math.random() * 10);
const getNextNumber = (num) => (num + 1) % 10;

const getResult = (result) => {
    // ���������
    if (Number.isInteger(result)) {
        const str = result.toString().padStart(3, '0');
        console.log(`Int: ${str}`);
        return {
            numbers: str.split('').map(Number), // ת������������
            decimalIndex: -1 // û��С���㣬���� -1 ��ʾû��
        };
    }

    // �����С���� >= 1
    if (result >= 1) {
        const str = Math.floor(result).toFixed(0).toString().padStart(3, '0'); // ����С������
        console.log(`>=1: ${str}`);
        return {
            numbers: str.split('').map(Number), // ת������������
            decimalIndex: -1 // û��С���㣬���� -1 ��ʾû��
        }; 
    }

    // �����0.1 <= result < 1 ����1λ��Ч���֣���0.1��0.9֮�䣩
    if (result >= 0.1 && result < 1 && Number(result.toFixed(1)) === result) {
        const str = (result * 10).toFixed(0).toString().padStart(3, '0'); // ��չ�ɰ�λ��ʮλ����λ
        console.log(`[0.1, 1): ${str}`);
        return {
            numbers: str.split('').map(Number), // ת������������
            decimalIndex: 1 // С�����ڸ�λ����
        };
    }

    // �����0.01 <= result < 0.1 ����2λ��Ч���֣���0.01��0.09֮�䣩
    if (result >= 0.01 && result < 0.1 && Number(result.toFixed(2)) === result) {
        const str = (result * 100).toFixed(0).toString().padStart(3, '0'); // ��չ�ɰ�λ��ʮλ����λ
        console.log(`[0.01, 0.1): ${str}`);
        return {
            numbers: str.split('').map(Number), // ת������������
            decimalIndex: 0 // С�����ڸ�λ����
        };
    }

    console.log(`Other: ${result}`);

    // Ĭ�Ϸ��ؿ����飨������Ԥ�����룩
    return {
        numbers: [], // ������
        decimalIndex: -1 // С�����ڸ�λ����
    };
};

const SlotMachine = ({ active, result, handleFinish }) => {
    const [numbers, setNumbers] = useState([0, 0, 0]);
    const [decimalIndex, setDecimalIndex] = useState(-1)
    const intervalRefs = useRef([null, null, null]); // ʹ�� useRef ����һ���ɱ����õ�����

    useEffect(() => {

        if (active === false) {
            intervalRefs.current.forEach(clearInterval); // ������м�ʱ��
            return;
        }

        // get result
        const resultTmp = getResult(result);
        const resultArray = resultTmp.numbers;
        const resultDecimalIndex = resultTmp.decimalIndex;

        console.log(`Result Array: ${resultArray}, Result Decimal Index ${resultDecimalIndex}`);

        // init
        setNumbers([getRandomNumber(), getRandomNumber(), getRandomNumber()]);
        setDecimalIndex(-1);
        // Ϊÿ���������������ļ�ʱ��
        intervalRefs.current = intervalRefs.current.map((_, index) => {
            return setInterval(() => {
                setNumbers((prevNumbers) => {
                    const newNumbers = [...prevNumbers];
                    newNumbers[index] = getNextNumber(newNumbers[index]);
                    return newNumbers;
                });
            }, 50);
        });

        // ����ÿ������ֹͣ��ʱ��
        const stopTimes = [2000, 2500, 3000];

        stopTimes.forEach((time, index) => {
            setTimeout(() => {
                if (intervalRefs.current[index]) {
                    clearInterval(intervalRefs.current[index]); // ȷ����ʱ������ȷ���
                }
                setNumbers((prevNumbers) => {
                    const newNumbers = [...prevNumbers];
                    newNumbers[index] = resultArray[index];
                    return newNumbers;
                });
                if (index === stopTimes.length - 1) {
                    setDecimalIndex(resultDecimalIndex);
                    setTimeout(() => {
                        handleFinish();
                    }, 500); // �����һ������ͣ�º�һ��ʱ���ٵ��� handleFinish
                }
            }, time);
        });
    }, [active, result, handleFinish]);

    return (
        <Box
            position="relative"
            width="22.5rem"  // ʹ��rem��λ
            height="22.4rem"  // ʹ��rem��λ
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                backgroundImage: 'url(/SlotMachine.webp)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                fontSize: '62.5%', // ���ø�Ԫ�������СΪ 10px������ rem �Ļ���
            }}
        >
            <Box
                position="absolute"
                top="43.5%"
                left="50%"
                transform="translate(-50%, -50%)"
                zIndex={2}
                width="0px"
                height="0px"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                    {numbers.map((number, index) => (
                        <React.Fragment key={index}>
                            <motion.div
                                animate={{ y: [0, 0] }}
                                transition={{ duration: 0.5 }}
                                style={{
                                    width: '0rem',  // ʹ��rem��λ
                                    height: '0rem',  // ʹ��rem��λ
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '6rem',
                                    margin: '0 2.25rem',  // ʹ��rem��λ
                                    color: '#000000',
                                    position: 'relative', // ����С���㸡��
                                }}
                            >
                                {number}
                                {/* �ڸ�λ����֮����ʾС���� */}
                                {index === decimalIndex && (
                                    <motion.div
                                        style={{
                                            position: 'absolute',
                                            left: '1.1rem', // ʹС������������֣�������ռ�ö���ռ�
                                            top: '-8.2rem',
                                            fontSize: '10rem', // ���ý�С�������С����Ӱ�����岼��
                                            color: '#000000',
                                        }}
                                    >
                                        .
                                    </motion.div>
                                )}
                            </motion.div>
                        </React.Fragment>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default SlotMachine;
