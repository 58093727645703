import { getRequestData } from '../tools/TgTools';

// !!! �Խ���ʽ��ʱ����Ҫ�޸� BASE_URL Ϊ��ʽ�����ĵ�ַ
// ��ʽ��URL 'https://api.happyhousetech.top/api/user/'
// ���԰�URL 'https://api.happyhousetech.top/tg_codebase/api/user/'

const BASE_URL = process.env.REACT_APP_API_URL; // 'https://api.happyhousetech.top/api/user/';

export const fetchData = async (url, userId, onSuccess, onError) => {
    try {
        const response = await fetch(`${url}?userId=${userId}`);
        const responseData = await response.json();
        if (!response.ok) {
            onError(`HTTP error! status: ${response.status}, message: ${responseData.message}`);
        } else if (responseData.code !== 1) {
            onError(`Error: ${responseData.message}`);
        } else {
            onSuccess(responseData.data);
        }
    } catch (error) {
        onError(`Error submitting form: ${error.message}`);
    }
};

export const postData = async (endpoint, additionalParams = null, onSuccess, onError) => {
    try {
        const requestData = getRequestData();
        // ����Ҫ���͵����ݶ��󣬰��� requestData �� additionalParams
        const dataToSend = {
            requestData,
            additionalParams
        };

        const response = await fetch(BASE_URL + endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });

        const responseData = await response.json();

        if (!response.ok) {
            // ������200ϵ״̬��
            onError(`HTTP error! status: ${response.status}, message: ${responseData.message}`);
        } else if (responseData.code !== 1) {
            // ����ҵ���߼�����
            onError(`Error: ${responseData.message}`);
        } else {
            // �����ɹ���Ӧ
            onSuccess(responseData.data);
        }
    } catch (error) {
        onError(`Error submitting form: ${error.message}`);
    }
};