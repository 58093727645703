import React from 'react';
import { Dialog, Card, CardContent, Box } from '@mui/material';
import { motion } from 'framer-motion';

const PopupCard = ({ open, onClose, width, height, children }) => {
    const variants = {
        hidden: { opacity: 0, scale: 0.9 },
        visible: { opacity: 1, scale: 1 },
    };
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                component: motion.div,
                initial: "hidden",
                animate: open ? "visible" : "hidden",
                variants: variants,
                transition: { duration: 0.3 },
                sx: {
                    width: width || '80%', // ���ÿ��ȣ�Ĭ��Ϊ 80%
                    height: height || 'auto', // ���ø߶ȣ�Ĭ��Ϊ 80%
                    margin: '5%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'linear-gradient(to bottom, #6ba3ff, #4a7bce)', // ���䱳��ɫ
                    borderRadius: '20px',
                    padding: '5px',
                    overflow: 'hidden',
                },
            }}
        >
            <Card sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    textAlign: 'center',
                    width: '100%',
                    height: '100%', // ���ø߶�Ϊ100%
                    maxWidth: '100%', // ����������Ϊ100%
                    maxHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <CardContent sx={{ width: '90%', height: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        {children}
                    </Box>
                </CardContent>
            </Card>
        </Dialog>
    );
};

export default PopupCard;
