import React, { useEffect, useState } from 'react';
import { trackEvent } from './GaTools';
//import { postData } from '../communications/Backend';
import {loadAdSDK} from './OpenADSDK';

//async function logAdEvent(adType, adEvent, adIndex) {
//    try {
//        await postData(
//            'ad-log',
//            { adType: adType, adEvent: adEvent, adIndex: adIndex },
//            (data) => {
//                console.info(`Ad Log Success ${adType} ${adEvent} ${adIndex}`);
//            },
//            (error) => {
//                console.error(`Ad Log Failed ${adType} ${adEvent} ${adIndex}`, error);
//            }
//        );
//    } catch (error) {
//        console.error(`Ad Log Failed ${adType} ${adEvent} ${adIndex}`, error);
//    }
//};

const onAdSDKLoadSuccess = () => {
    trackEvent({
        category: 'AdScriptLoad',
        action: 'Success',
    });
    console.log(`Ad SDK Load Success`);
}

const onAdSDKLoadFailed = () => {
    trackEvent({
        category: 'AdScriptLoad',
        action: 'Failed',
    });
    console.log(`Ad SDK Load Failed`);
}

const onAdLoadStart = (bannerIndex, slot) => {
    trackEvent({
        category: 'AdLoad',
        action: 'Start',
        label: `${slot}-${bannerIndex}`
    });
    //logAdEvent("banner", "load", bannerIndex);
}

const onAdLoadFailed = (bannerIndex, slot, error) => {
    trackEvent({
        category: 'AdLoad',
        action: 'Failed',
        label: `${slot}-${bannerIndex}-${error}`,
    });
}

const onAdLoadSuccess = (bannerIndex, slot) => {
    trackEvent({
        category: 'AdLoad',
        action: 'Success',
        label: `${slot}-${bannerIndex}`
    });
    //logAdEvent("banner", "loadSuccess", bannerIndex);
}

const onAdDisplaySuccess = (bannerIndex, slot) => {
    trackEvent({
        category: 'AdDisplay',
        action: 'Success',
        label: `${slot}-${bannerIndex}`
    });
    //logAdEvent("banner", "display", bannerIndex);
}

const onAdDisplayFailed = (bannerIndex, slot, error) => {
    trackEvent({
        category: 'AdDisplay',
        action: 'Failed',
        label: `${slot}-${bannerIndex}-${error}`,
    });
}

const onAdClicked = (bannerIndex, slot) => {
    trackEvent({
        category: 'AdClicked',
        action: 'Success',
        label: `${slot}-${bannerIndex}`
    });
    //logAdEvent("banner", "click", bannerIndex);
}

export function DisplayBanner({bannerIndex, slot}) {
    const [TGAD, setTGAD] = useState({
        adInfo: {
            /* ------ test ----- */
            //zoneId: bannerIndex == 0 ? 95 : 79,
            //publisherId: bannerIndex == 0 ? 69 : 56,
            //eventId: 0,
            /* ------ prod ----- */
            zoneId: bannerIndex == 0 ? 92 : 136,
            publisherId: 63,
            eventId: 0,
        },
        adParams: {
            version: 'v3',
            TG: {
                /* ------ test ----- */
                //type: 'noSDK',
                //FN: null,
                /* ------ prod ----- */
                type: 'telegram',
                FN: window.Telegram
            },
        },
        banner: {
            width: null,
            type: 'banner',
            height: null,
            resource_id: null,
            resource_url: '',
            resource_text: '',
            resource_desc: '',
        },
    });

    useEffect(() => {
        const startLoadAd = async () => {
            const success = await loadAdSDK(onAdSDKLoadSuccess, onAdSDKLoadFailed);
            if (success && window.openADJsSDK) {
                console.log(`Proceed with ad initialization - Banner ${bannerIndex}`);
                await getAD();
            } else {
                console.error(`SDK failed to load - Banner ${bannerIndex}`);
            }
        };
        startLoadAd();
    }, [bannerIndex, slot]);

    const getAD = async () => {
        onAdLoadStart(bannerIndex, slot);
        console.log(`Load Start - Banner ${bannerIndex}`);

        let res = await window.openADJsSDK.bridge.get({ adInfo: TGAD.adInfo, adParams: TGAD.adParams });
        if (res.code === 0) {
            onAdLoadSuccess(bannerIndex, slot);
            console.log(`Load Success - Banner ${bannerIndex}`);

            setTGAD((prev) => ({ ...prev, banner: res.data }));
            await CallBackLogInfo(TGAD.adInfo);
        } else {
            onAdLoadFailed(bannerIndex, slot, res.code);
            console.log(`Load Failed - Banner ${bannerIndex}`, res.code);
        }
    };

    const CallBackLogInfo = async (adInfo) => {
        console.log(`Check Start - Banner ${bannerIndex}`);
        let res = await window.openADJsSDK.bridge.log(adInfo);
        if (res.code === 0) {
            onAdDisplaySuccess(bannerIndex, slot);
            console.log(`Check Success - Banner ${bannerIndex}`);
        } else {
            onAdDisplayFailed(bannerIndex, slot, res.msg);
            console.log(`Check Failed - Banner ${bannerIndex}`);
        }
    };

    const CallBackClickInfo = (adInfo) => {
        onAdClicked(bannerIndex, slot);
        console.log(`Click Start - Banner ${bannerIndex}`);
        window.openADJsSDK.bridge.click(adInfo);
    };

    return (
        <>
            {TGAD.banner.resource_url && TGAD.banner.width && TGAD.banner.height && (
                <div className="openADJsSDKBanner TGAD">
                    <a href="#" className="Flex" onClick={() => CallBackClickInfo(TGAD.adInfo)}>
                        <img
                            src={TGAD.banner.resource_url}
                            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                            alt="TG Ad"
                        />
                    </a>
                </div>
            )}
        </>
    );
};
