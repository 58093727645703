import { getAppName, getBotUserName, getChannelUserName } from '../datas/Data';

const isTgEnv = process.env.REACT_APP_TG_ENV_CHECK === 'true';
let telegram = null;
let cachedRequestData = null;

const fakeRequestData = {
    auth_date: "1718164814",
    chat_instance: "-716352307747511557",
    chat_type: "private",
    hash: "bd0fa0511ef12fbbee038d1a7e2eea0f06e382527f37ef9d2e548a57713db598",
    start_param: "5646152229",
    // user 使用前需要反序列化，转换为对象
    user: "{\"id\":7104293306,\"first_name\":\"Ren\",\"last_name\":\"Zheng\",\"language_code\":\"zh-hans\",\"allows_write_to_pm\":true}",
}

export const initTelegram = () => {
    console.log('Init Telegram');

    if (!isTgEnv)
        return;

    if (window.Telegram && window.Telegram.WebApp) {
        telegram = window.Telegram.WebApp;
        telegram.ready();
        telegram.expand();
    } else {
        console.error('Telegram WebApp not available');
    }
};

export const getRequestData = () => {
    if (!isTgEnv)
        return fakeRequestData;

    if (cachedRequestData)
        return cachedRequestData;

    if (!telegram || !telegram.initData) {
        console.error('Telegram WebApp not initialized or user data not available');
        return null;
    }

    const initData = telegram.initData;
    const query = new URLSearchParams(initData);
    cachedRequestData = Object.fromEntries(query.entries());
    return cachedRequestData;
};

export const getUserId = () => {
    const requestData = getRequestData();
    if (requestData && requestData.user) {
        const user = JSON.parse(requestData.user);
        return user.id;
    } else {
        return null;
    }
}

export const openTelegramLink = (link) => {

    if (isTgEnv) {
        if (!telegram) {
            console.error('Telegram WebApp not initialized');
            return;
        }

        if (link) {
            telegram.openTelegramLink(link);
        }
    } else {
        console.log(`Open telegram link: ${link}`);
    }
};

export const getChannelLink = () => `https://t.me/${getChannelUserName()}`;

export const getInviteLink = () => `https://t.me/${getBotUserName()}/${getAppName()}?startapp=${getUserId()}`;

export const getInviteText = (amount = 0) => {
    const giftEmoji = '🎁';
    const goldEmoji = '💰';
    if (amount > 0)
        return `${giftEmoji}I've won ₹${amount} from this Game!${giftEmoji}\nClick URL and play with me!\n${goldEmoji}Let's strike it rich together!${goldEmoji}\n`;
    else
        return `${giftEmoji}I've found a great earning Game!${giftEmoji}\nClick URL and play with me!\n${goldEmoji}Let's strike it rich together!${goldEmoji}\n`;
}

export const inviteUserToChannel = (inviteLink = '', text = '') => {
    const url = `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}&text=${encodeURIComponent(text)}`;
    window.open(url, '_blank');
};
