import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { postData } from '../communications/Backend';
import LoadingSpinner from '../components/LoadingSpinner';
import PopupCard from '../components/PopupCard'; // 导入 PopupCard 组件
import PopupTitle from '../components/PopupTitle';
import PopupContent from '../components/PopupContent';
import PopupInfo from '../components/PopupInfo';
import ButtonPair from '../components/ButtonPair';
import ButtonSingle from '../components/ButtonSingle';
import { trackException, trackEvent } from '../tools/GaTools';

export function WithdrawRecordPopup({ open, onClose, setWithdrawInfo }) {

    const [withdrawRecord, setWithdrawRecord] = useState([]);
    const [loading, setLoading] = useState(true);
    const [timeoutError, setTimeoutError] = useState(false);
    const [dataFetchError, setDataFetchError] = useState(false);

    const fetchUserWithdrawRecord = async () => {
        try {
            await postData(
                'withdraw-record',
                null,
                (data) => {
                    if (data.orderRecords && Array.isArray(data.orderRecords)) {
                        const formattedRecords = data.orderRecords.map(order => ({
                            ...order,
                            orderTime: new Date(order.orderTime).toLocaleString(),
                            totalAmount: `₹${order.totalAmount}`,
                            orderStatus: order.orderStatus.toUpperCase()
                        }));
                        setWithdrawRecord(formattedRecords);
                        setWithdrawInfo(prev => ({
                            ...prev,
                            pending: data.pending,
                            withdrawn: data.withdrawn,
                            inProgress: data.inProgress,
                        }))
                        trackEvent({
                            category: 'User',
                            action: 'Fetched',
                            label: 'WithdrawRecord',
                        });
                    } else {
                        console.warn('User Withdraw Record Format Error');
                        setDataFetchError(true);
                        trackException({
                            description: `User Withdraw Record Failed: Format Error`,
                        });
                    }
                },
                (error) => {
                    console.error('User Withdraw Record Failed', error);
                    setDataFetchError(true);
                    trackException({
                        description: `User Withdraw Record Failed: ${error}`,
                    });
                }
            );
        } catch (error) {
            console.error('User Withdraw Record Failed Catched', error);
            setDataFetchError(true);
            trackException({
                description: `User Withdraw Record Failed: ${error}`,
            });
        }
    };

    const fetchAndRefreshData = async () => {
        setLoading(true);
        setTimeoutError(false);
        setDataFetchError(false);

        const timeoutId = setTimeout(() => {
            setLoading(false);
            setTimeoutError(true);
            console.log('Withdraw Record Time Out');
            trackException({
                description: `User Withdraw Record Fetched Time out`,
            });
        }, 10000); // 10秒超时

        try {
            await fetchUserWithdrawRecord();
            clearTimeout(timeoutId);
            setLoading(false);
        } catch (error) {
            clearTimeout(timeoutId);
            setLoading(false);
            setDataFetchError(true);
            trackException({
                description: `User Withdraw Fetched Failed: ${error}`,
            });
        }

        return timeoutId;
    };

    const handleWithdrawRefresh = () => {
        fetchAndRefreshData();
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: "WithdrawRefresh",
        });
    };

    useEffect(() => {
        if (open) {
            const timeoutId = fetchAndRefreshData();
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [open]);

    const WithdrawRecordTotal = ({ record }) => {
        return (
            <Typography
                variant="body1"
                width="100%"
                sx={{
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                    color: '#FFFFFF',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)', // 添加文字阴影
                    textAlign: 'left', // 文本居左
                    whiteSpace: 'pre-line', // 保留换行符
                    mt: 0,
                }}>
                You have {record.length} withdrawal history records:
            </Typography>
        );
    }

    const WithdrawRecordDetail = ({ record }) => {
        if (record.length > 0) {
            return (
                <Box
                    width="100%"
                    sx={{
                        color: '#FFFFFF',
                        textAlign: 'left', // 文本居左
                        textShadow: '2px 2px 4px rgba(0,0,0,0.3)', // 添加文字阴影
                        mt: 1,
                    }}
                >
                    {record.map((record, index) => (
                        <Box key={index}>
                            <Typography sx={{ fontSize: '0.875rem' }}>
                                {record.orderTime} - <span style={{ color: '#FFEBA2' }}>{record.totalAmount}</span> - {record.orderStatus}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            );
        } else {
            return null;
        }
    }

    return (
        <>
            <PopupCard
                open={open}
                onClose={onClose}
            >
                <PopupTitle title="Withdrawal Record" />
                <PopupContent>
                    <WithdrawRecordTotal record={withdrawRecord} />
                    <WithdrawRecordDetail record={withdrawRecord} />
                </PopupContent>

                <ButtonPair
                    confirmInfo="Refresh"
                    closeInfo="Close"
                    onConfirm={handleWithdrawRefresh}
                    onClose={onClose}
                />

            </PopupCard>

            {loading && <LoadingSpinner />}

            <PopupCard
                open={timeoutError}
                onClose={onClose}
            >
                <PopupTitle title="Error" />
                <PopupContent>
                    <PopupInfo info={
                        `Network connection error
                         Try again later!` } />
                </PopupContent>
                <ButtonSingle onClick={onClose} clickInfo="Close" />
            </PopupCard>

            <PopupCard
                open={dataFetchError}
                onClose={onClose}
            >
                <PopupTitle title="Error" />
                <PopupContent>
                    <PopupInfo info={
                        `Network connection error
                         Try again later!` } />
                </PopupContent>
                <ButtonSingle onClick={onClose} clickInfo="Close" />
            </PopupCard>
            
        </>
    );
}
