import React from 'react';
import { Button, Typography } from '@mui/material';


const ButtonNormal = ({ onClick, buttonInfo, colors, sx }) => {
    const { baseColor, gradientStart, gradientEnd } = colors;
    return (
        <Button
            onClick={onClick}
            sx={{
                backgroundColor: baseColor, // ������ɫ
                background: `linear-gradient(to bottom, ${gradientStart}, ${gradientEnd})`, // ���䱳��ɫ
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', // �ⲿ��ӰЧ��
                height: '100%',
                width: '100%',
                borderRadius: '14px', // Բ��
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...sx // �ϲ������sx����
            }}
        >
            <Typography
                variant="h5"
                fontWeight="bold"
                color="white"
                style={{ textTransform: 'none' }}
                sx={{ display: 'flex', whiteSpace: 'nowrap', textShadow: '1px 1px 2px #000000' }} >
                {buttonInfo}
            </Typography>
        </Button>
    );
};

export default ButtonNormal;
