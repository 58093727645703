import React from 'react';
import LoadingSpinner from '../components/LoadingSpinner';
import PopupCard from '../components/PopupCard';
import PopupTitle from '../components/PopupTitle';
import PopupContent from '../components/PopupContent';
import PopupInfo from '../components/PopupInfo';
import ButtonPair from '../components/ButtonPair';
import ButtonSingle from '../components/ButtonSingle';
import InfoCard from '../components/InfoCard';
import { inviteUserToChannel, getInviteText, getInviteLink } from '../tools/TgTools';
import { trackEvent } from '../tools/GaTools';

const WithdrawProcess = ({ withdrawInfo, getTicket, closeWithdrawProgress, openWalletInfoEdit, openWithdrawRecord, confirmWithdraw, miniAppVisitUrl }) => {

    const handleEditWalletInfo = () => {
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: 'WalletEdit',
        });
        closeWithdrawProgress();
        openWalletInfoEdit();
    };
    const handleInvite = () => {
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: 'Invite',
        });
        closeWithdrawProgress();
        let link = null;
        if (miniAppVisitUrl === null) {
            link = getInviteLink();
        } else {
            link = miniAppVisitUrl;
        }
        const text = getInviteText(withdrawInfo.pending + withdrawInfo.withdrawn + withdrawInfo.inProgress);
        inviteUserToChannel(link, text);
    };
    
    const renderWithdrawInfoCard = () => (
        <InfoCard
            title="Cash Out Info"
            fields={[
                { label: 'To Be Cashed Out', type: 'text', name: 'toWithdraw', value: `₹${withdrawInfo.pending}` },
                { label: 'Success Withdrawal', type: 'text', name: 'withdrawn', value: `₹${withdrawInfo.withdrawn}` },
                { label: 'Under Review', type: 'text', name: 'inProgress', value: `₹${withdrawInfo.inProgress}` },            ]}
            buttonText="Record"
            buttonAction={openWithdrawRecord}
            sx={{
                minHeight: '11.5rem',
            }}
        />
    );

    const renderWithdrawWaitingPopup = () => {
        let info = null;
        const ticket = getTicket();
        switch (withdrawInfo.withdrawStatus) {
            case 'idle':
                return null;
            case 'loading':
                return <LoadingSpinner />;
            case 'success':
                info = `Cash out submitted successfully!
                        This will be manually verified
                        You will receive ₹${withdrawInfo.withdrawCount} in 7 days
                        You have earned ₹${withdrawInfo.withdrawn + withdrawInfo.inProgress} in total`;
                return (
                    <PopupCard
                        open={true}
                        onClose={closeWithdrawProgress}
                        width="70%"
                    >
                        <PopupTitle title="Congratulations" />
                        <PopupContent>
                            <PopupInfo info={info} />
                        </PopupContent>
                        <ButtonSingle onClick={closeWithdrawProgress} clickInfo="Close" />
                    </PopupCard>
                );
            case 'validation_success':
                //info = `You can cash out ₹${withdrawInfo.pending}!
                //        It will cost you ${withdrawInfo.ticketRequirement} gems
                //        You have ${ticket} now
                //        Click CASH OUT to continue!`;
                info = (
                    <>
                        You can cash out ₹{withdrawInfo.pending}!
                        {'\n'}
                        It will cost you
                        <img src="/icon-diamond.png" alt="icon" style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                        x{withdrawInfo.ticketRequirement}.
                        {'\n'}
                        Now you have
                        <img src="/icon-diamond.png" alt="icon" style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                        x{ticket}.
                        {'\n'}
                        Click CASH OUT to continue!
                    </>
                );
                return (
                    <PopupCard
                        open={true}
                        onClose={closeWithdrawProgress}
                        width="70%"
                    >
                        <PopupTitle title="Tips" />
                        <PopupContent>
                            <PopupInfo info={info} />
                        </PopupContent>
                        <ButtonPair
                            confirmInfo="Cash Out"
                            closeInfo="Close"
                            onConfirm={confirmWithdraw}
                            onClose={closeWithdrawProgress}
                        />
                    </PopupCard>
                );
            case 'error_insufficient_funds':
                info = `Insufficient balance for withdrawal!
                        Your withdrawable balance is ₹${withdrawInfo.pending}
                        Invite more friends and play now!`;
                return (
                    <PopupCard
                        open={true}
                        onClose={closeWithdrawProgress}
                        width="70%"
                    >
                        <PopupTitle title="Error" />
                        <PopupContent>
                            <PopupInfo info={info} />
                        </PopupContent>
                        <ButtonPair
                            confirmInfo="Invite"
                            closeInfo="Close"
                            onConfirm={handleInvite}
                            onClose={closeWithdrawProgress}
                        />
                    </PopupCard>
                );
            case 'error_insufficient_invitation':
                //info = `Insufficient Gems!
                //        To cash out ₹${withdrawInfo.pending}
                //        You need ${withdrawInfo.ticketRequirement} gems
                //        You have ${ticket} now
                //        You need ${withdrawInfo.ticketRequirement - ticket} more gems.
                //        Invite more friends now!`;
                info = (
                    <>
                        To cash out ₹{withdrawInfo.pending}
                        {'\n'}
                        You need
                        <img src="/icon-diamond.png" alt="icon" style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                        x{withdrawInfo.ticketRequirement}.
                        {'\n'}
                        Now you have
                        <img src="/icon-diamond.png" alt="icon" style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                        x{ticket}.
                        {'\n'}
                        Invite more friends now!
                    </>
                );
                return (
                    <PopupCard
                        open={true}
                        onClose={closeWithdrawProgress}
                        width="70%"
                    >
                        <PopupTitle title="Error" />
                        <PopupContent>
                            <PopupInfo info={info} />
                        </PopupContent>
                        <ButtonPair
                            confirmInfo="Invite"
                            closeInfo="Close"
                            onConfirm={handleInvite}
                            onClose={closeWithdrawProgress}
                        />
                    </PopupCard>
                );
            case 'error_empty_wallet_info':
                return (
                    <PopupCard
                        open={true}
                        onClose={closeWithdrawProgress}
                        width="70%"
                    >
                        <PopupTitle title="Error" />
                        <PopupContent>
                            <PopupInfo info={
                                `UPI is supported now!
                                 You haven't filled in your Account Info yet.
                                 Please complete the Account Info first.`} />
                        </PopupContent>
                        <ButtonPair
                            confirmInfo="Edit"
                            closeInfo="Close"
                            onConfirm={handleEditWalletInfo}
                            onClose={closeWithdrawProgress}
                        />
                    </PopupCard>
                );
            case 'error_network':
                return (
                    <PopupCard
                        open={true}
                        onClose={closeWithdrawProgress}
                        width="70%"
                    >
                        <PopupTitle title="Error" />
                        <PopupContent>
                            <PopupInfo info={
                                `Network connection error
                                 Try again later!` } />
                        </PopupContent>
                        <ButtonSingle onClick={closeWithdrawProgress} clickInfo="Close" />
                    </PopupCard>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {renderWithdrawInfoCard()}
            {renderWithdrawWaitingPopup()}
        </>
    );
};

export default WithdrawProcess;
