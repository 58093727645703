import ReactGA from 'react-ga4';

// ��ʼ��Google Analytics
export const initGA = () => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
};

// ��¼ҳ����ͼ
export const trackPageView = (page) => {
    ReactGA.send({ hitType: 'pageview', page });
};

// ��¼�Զ����¼�
export const trackEvent = ({ category, action, label = '', value = 0, nonInteraction = false }) => {
    ReactGA.event({
        category,
        action,
        label,
        value,
        nonInteraction,
    });
};

// ��¼�쳣
export const trackException = ({ description, fatal = false }) => {
    ReactGA.event({
        category: 'Exception', 
        action: fatal ? 'Fatal Error' : 'Non-Fatal Error',
        label: description,
        nonInteraction: true,
    });
};